/* eslint-disable prettier/prettier */
import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import prescriptionSagas from './prescription/saga';
import orderSagas from './order/saga';
import userSagas from './user/saga';
import deliverableSaga from './deliverables/saga';
import pickupSaga from './pickup/saga';
import deliverySaga from './deliveryDrivers/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    prescriptionSagas(),
    orderSagas(),
    userSagas(),
    deliverableSaga(),
    pickupSaga(),
    deliverySaga(),
  ]);
}
