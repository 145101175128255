/* eslint-disable prettier/prettier */
import {
  LIST_USER,
  LIST_USER_SUCCESS,
  LIST_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
} from "../constants";

const INIT_STATE = {
  users: [],
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_USER: 
      return { ...state, loading: true, error: "" };
    case LIST_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
        error: "",
      };
    case LIST_USER_ERROR:
      return {
        ...state,
        loading: false,
        users: [],
        error: action.payload,
      };
    case UPDATE_USER:
      return { ...state, loading: true, error: '' };
    case UPDATE_USER_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newState = state.users.map((user) => {
        // eslint-disable-next-line no-underscore-dangle
        if (user._id === action.payload._id) {
          return action.payload;
        }
        return user;
      })
      return {
        ...state,
        loading: false,
        users: newState,
        error: '',
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        users: [],
        error: action.payload.message,
      };
    case DELETE_USER:
      return { ...state, loading: true, error: '' };
    case DELETE_USER_SUCCESS:
      // eslint-disable-next-line no-case-declarations, no-underscore-dangle
      const afterDelete = state.users.filter((user) => user._id !== action.payload);
      return {
        ...state,
        loading: false,
        users: afterDelete,
        error: '',
      };
    case DELETE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return { ...state };
  }
};
