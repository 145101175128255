import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import prescriptionList from './prescription/reducer';
import orderList from './order/reducer';
import users from './user/reducer';
import deliverables from './deliverables/reducer';
import pickups from './pickup/reducer';
import deliveryDrivers from './deliveryDrivers/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  prescriptionList,
  orderList,
  users,
  deliverables,
  pickups,
  deliveryDrivers
});

export default reducers;
