/* eslint-disable no-unused-vars */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { auth } from 'helpers/Firebase';
import api from "../../api/API";
import {
  LIST_PRESCRIPTION,
  UPDATE_PRESCRIPTION,
  DELETE_PRESCRIPTION,
} from "../constants";

import {
  listPrescriptionSuccess,
  listPrescriptionError,
  updatePrescriptionSuccess,
  updatePresriptionError,
  deletePrescriptionSuccess,
  deletePrescriptionError,
} from "./actions";

export function* watchListPrescription() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LIST_PRESCRIPTION, listPrescriptions);
}

// eslint-disable-next-line no-unused-vars
const listPrescriptionsAsync = async () =>
  // eslint-disable-next-line no-return-await
  await api
    .get(`prescriptions/panel`)
    .then((user) => user)
    .catch((error) => error);
function* listPrescriptions() {
  try {
    const prescription = yield call(listPrescriptionsAsync);
    if (prescription.prescriptions) {
      yield put(listPrescriptionSuccess(prescription.prescriptions));
      // history.push(adminRoot);
    } else {
      yield put(listPrescriptionError(prescription?.message));
    }
  } catch (error) {
    yield put(listPrescriptionError(error));
  }
}
export function* watchUpdatePrescription() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_PRESCRIPTION, updatePrescription);
}
// eslint-disable-next-line no-unused-vars
const updatePrescriptionAsync = async (payload) =>
  // eslint-disable-next-line no-return-await
  await api
    .put(`prescriptions/panel`, payload)
    .then((presReturn) => presReturn)
    .catch((error) => error);
function* updatePrescription({ payload }) {
  try {
    const prescription = yield call(updatePrescriptionAsync, payload);
    if (prescription.prescription) {
      yield put(updatePrescriptionSuccess(prescription.prescription));
      // history.push(adminRoot);
    } else {
      yield put(updatePresriptionError(prescription?.message));
    }
  } catch (error) {
    yield put(updatePresriptionError(error));
  }
}
export function* watchDeletePrescription() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_PRESCRIPTION, deletePrescription);
}
// eslint-disable-next-line no-unused-vars
const deletePrescriptionAsync = async (prescription) =>
  // eslint-disable-next-line no-return-await
  await api
    .delete(`prescriptions/panel/${prescription}`)
    // eslint-disable-next-line no-underscore-dangle
    .then((deletePres) => deletePres?.prescription?._id)
    .catch((error) => error);
function* deletePrescription({ payload }) {
  try {
    const presID = yield call(deletePrescriptionAsync, payload);
    if (presID) {
      yield put(deletePrescriptionSuccess(presID));
      // history.push(adminRoot);
    } else {
      yield put(deletePrescriptionError(presID?.message));
    }
  } catch (error) {
    yield put(deletePrescriptionError(error));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchListPrescription),
    fork(watchUpdatePrescription),
    fork(watchDeletePrescription),
  ]);
}
