import isUndefined from 'lodash/isUndefined';
import trimStart from 'lodash/trimStart';
import axios from 'axios';
import { configAPI } from 'constants/defaultValues';

export const SECONDS_PER_DAY = 60 * 60 * 24;

/**
 * API methods
 */
class API {

  constructor() {
    this.config = configAPI;
    this.index = 0;
    this.pending = {};
    this.timeouts = {};
    this.token = localStorage.getItem(this.config.token || 'token');
  }

  /**
   * Get root
   */
  get root() {
    return this.config.root || '';
  }

  /**
   * Delete
   */
  delete(endpoint, config) {
    return this.request('delete', endpoint, config);
  }

  /**
   * Get
   */
  get(endpoint, config) {
    return this.request('get', endpoint, config);
  }

  /**
   * Post
   */
  post(endpoint, data, config) {
    return this.request('post', endpoint, config, data);
  }

  /**
   * Put
   */
  put(endpoint, data, config) {
    return this.request('put', endpoint, config, data);
  }

  /**
   * Remove token
   */
  removeToken() {
    localStorage.removeItem(this.config.token || 'token');
    return this;
  }

  /**
   * Perform request
   */
  request(method, endpoint, config = {}, data = {}) {
    const args = [this.root + trimStart(endpoint, '/')];
    if (['post', 'put'].indexOf(method) >= 0) {
      args.push(data);
    }
    if (isUndefined(config.headers)) {
      // eslint-disable-next-line no-param-reassign
      config.headers = {};
    }
    if (this.token) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${this.token}`;
    }
    
    args.push(config);
    // console.log(`config`, args);
    return axios[method](...args)
      .then((response) => {
        // this.setToken(token);
        return response.data;
      })
      .catch((exception) => {
        return Promise.reject(exception?.response?.data);
      });
  }

  /**
   * Set token
   */
  setToken(token) {
    // cookie.set(this.config.token || 'token', token, {
    //   expires: expires / SECONDS_PER_DAY
    // });
    localStorage.setItem(this.config.token || 'token', token);
    this.token = token;
    return token;
  }
}
const api = new API();
export default api;