/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { auth } from 'helpers/Firebase';
import api from "../../api/API";
import {
  LIST_ORDER,
  UPDATE_ORDER,
  DELETE_ORDER,
} from "../constants";

import {
  listOrderSuccess,
  listOrderError,
  updateOrderSuccess,
  updateOrderError,
  deleteOrderSuccess,
  deleteOrderError,
} from "./actions";

export function* watchListOrder() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LIST_ORDER, listOrders);
}

// eslint-disable-next-line no-unused-vars
const listOrdersAsync = async () =>
  // eslint-disable-next-line no-return-await
  await api
    .get(`orders/panel`)
    .then((user) => user)
    .catch((error) => error);
function* listOrders() {
  try {
    const order = yield call(listOrdersAsync);
    if (order.orders) {
      yield put(listOrderSuccess(order.orders));
      // history.push(adminRoot);
    } else {
      yield put(listOrderError(order?.message));
    }
  } catch (error) {
    yield put(listOrderError(error));
  }
}
export function* watchUpdateOrder() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_ORDER, updateOrder);
}
// eslint-disable-next-line no-unused-vars
const updateOrderAsync = async ({ order, order_status }) =>
  // eslint-disable-next-line no-return-await
  await api
    .put(`orders/panel`, {
      order,
      order_status
    })
    .then((presReturn) => presReturn)
    .catch((error) => error);
function* updateOrder({ payload }) {
  try {
    const order = yield call(updateOrderAsync, payload);
    if (order.order) {
      yield put(updateOrderSuccess(order.order));
      // history.push(adminRoot);
    } else {
      yield put(updateOrderError(order?.message));
    }
  } catch (error) {
    yield put(updateOrderError(error));
  }
}
export function* watchDeleteOrder() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_ORDER, deleteOrder);
}
// eslint-disable-next-line no-unused-vars
const deleteOrderAsync = async (order) =>
  // eslint-disable-next-line no-return-await
  await api
    .delete(`orders/panel/${order}`)
    // eslint-disable-next-line no-underscore-dangle
    .then((delOrder) => delOrder?.order?._id)
    .catch((error) => error);
function* deleteOrder({ payload }) {
  try {
    const orderID = yield call(deleteOrderAsync, payload);
    if (orderID) {
      yield put(deleteOrderSuccess(orderID));
      // history.push(adminRoot);
    } else {
      yield put(deleteOrderError(orderID?.message));
    }
  } catch (error) {
    yield put(deleteOrderError(error));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchListOrder),
    fork(watchUpdateOrder),
    fork(watchDeleteOrder),
  ]);
}
