import {
  LIST_PRESCRIPTION,
  LIST_PRESCRIPTION_SUCCESS,
  LIST_PRESCRIPTION_ERROR,
  UPDATE_PRESCRIPTION,
  UPDATE_PRESCRIPTION_SUCCESS,
  UPDATE_PRESCRIPTION_ERROR,
  DELETE_PRESCRIPTION,
  DELETE_PRESCRIPTION_SUCCESS,
  DELETE_PRESCRIPTION_ERROR,
} from "../constants";

export const listPrescription = (status) => ({
  type: LIST_PRESCRIPTION,
  payload: { status },
});
export const listPrescriptionSuccess = (prescriptions) => ({
  type: LIST_PRESCRIPTION_SUCCESS,
  payload: prescriptions,
});
export const listPrescriptionError = (prescriptionListError) => ({
  type: LIST_PRESCRIPTION_ERROR,
  payload: prescriptionListError,
});

export const updatePrescription = (updatePrescript) => ({
  type: UPDATE_PRESCRIPTION,
  payload: updatePrescript,
});
export const updatePrescriptionSuccess = (updatePrescript) => ({
  type: UPDATE_PRESCRIPTION_SUCCESS,
  payload: updatePrescript,
});
export const updatePresriptionError = (updatePresError) => ({
  type: UPDATE_PRESCRIPTION_ERROR,
  payload: updatePresError,
});

export const deletePrescription = (prescription) => ({
  type: DELETE_PRESCRIPTION,
  payload: prescription,
});
export const deletePrescriptionSuccess = (prescriptionID) => ({
  type: DELETE_PRESCRIPTION_SUCCESS,
  payload: prescriptionID,
});
export const deletePrescriptionError = (prescriptionError) => ({
  type: DELETE_PRESCRIPTION_ERROR,
  payload: prescriptionError,
});