/* eslint-disable prettier/prettier */
import {
  LIST_DELIVERABLE,
  LIST_DELIVERABLE_SUCCESS,
  LIST_DELIVERABLE_ERROR,
  UPDATE_DELIVERABLE,
  UPDATE_DELIVERABLE_SUCCESS,
  UPDATE_DELIVERABLE_ERROR,
  DELETE_DELIVERABLE,
  DELETE_DELIVERABLE_SUCCESS,
  DELETE_DELIVERABLE_ERROR,
} from '../constants';

export const listDeliverable = (status) => ({
  type: LIST_DELIVERABLE,
  payload: { status },
});
export const listDeliverableSuccess = (orders) => ({
  type: LIST_DELIVERABLE_SUCCESS,
  payload: orders,
});
export const listDeliverableError = (orderListErr) => ({
  type: LIST_DELIVERABLE_ERROR,
  payload: orderListErr,
});

export const updateDeliverable = (updateDeliverablePayload) => ({
  type: UPDATE_DELIVERABLE,
  payload: updateDeliverablePayload,
});
export const updateDeliverableSuccess = (updateDeliverablelist) => ({
  type: UPDATE_DELIVERABLE_SUCCESS,
  payload: updateDeliverablelist,
});
export const updateDeliverableError = (updateDeliverableErr) => ({
  type: UPDATE_DELIVERABLE_ERROR,
  payload: updateDeliverableErr,
});

export const deleteDeliverable = (obj) => ({
  type: DELETE_DELIVERABLE,
  payload: obj,
});
export const deleteDeliverableSuccess = (id) => ({
  type: DELETE_DELIVERABLE_SUCCESS,
  payload: id,
});
export const deleteDeliverableError = (orderErr) => ({
  type: DELETE_DELIVERABLE_ERROR,
  payload: orderErr,
});
