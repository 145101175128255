/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { auth } from 'helpers/Firebase';
import api from "../../api/API";
import {
  LIST_PICKUP,
  UPDATE_PICKUP,
  DELETE_PICKUP,
  ADD_PICKUP
} from "../constants";

import {
  addPickupSuccess,
  addPickupError,
  listPickupSuccess,
  listPickupError,
  updatePickupSuccess,
  updatePickupError,
  deletePickupSuccess,
  deletePickupError,
} from "./actions";

export function* watchListPickup() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LIST_PICKUP, listPickups);
}

// eslint-disable-next-line no-unused-vars
const listPickupsAsync = async ({ status }) =>
  // eslint-disable-next-line no-return-await
  await api
    .get(`pickups?status=${status}`)
    .then((pickups) => pickups)
    .catch((error) => error);
function* listPickups({ payload }) {
  try {
    const pickup = yield call(listPickupsAsync, payload);
    if (pickup.pickups) {
      yield put(listPickupSuccess(pickup.pickups));
      // history.push(adminRoot);
    } else {
      yield put(listPickupError(pickup?.message));
    }
  } catch (error) {
    yield put(listPickupError(error));
  }
}
export function* watchUpdatePickup() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_PICKUP, updatePickup);
}
// eslint-disable-next-line no-unused-vars
const updatePickupAsync = async ({ pickup, status, order }) =>
  // eslint-disable-next-line no-return-await
  await api
    .put(`pickups/${pickup}`, {
      status,
      order
    })
    .then((presReturn) => presReturn)
    .catch((error) => error);
function* updatePickup({ payload }) {
  try {
    const pickup = yield call(updatePickupAsync, payload);
    if (pickup.pickup) {
      yield put(updatePickupSuccess(pickup.pickup));
      // history.push(adminRoot);
    } else {
      yield put(updatePickupError(pickup?.message));
    }
  } catch (error) {
    yield put(updatePickupError(error));
  }
}

export function* watchAddPickup() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(ADD_PICKUP, addNewPickup);
}
// eslint-disable-next-line no-unused-vars
const addPickupAsync = async (payload) =>
  // eslint-disable-next-line no-return-await
  await api
    .post(`pickups`, payload)
    .then((pickup) => pickup)
    .catch((error) => error);
function* addNewPickup({ payload }) {
  try {
    const pickup = yield call(addPickupAsync, payload);
    if (pickup.pickup) {
      yield put(addPickupSuccess(pickup.pickup));
      // history.push(adminRoot);
    } else {
      yield put(addPickupError(pickup?.message));
    }
  } catch (error) {
    yield put(addPickupError(error));
  }
}
export function* watchDeletePickup() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_PICKUP, deletePickup);
}
// eslint-disable-next-line no-unused-vars
const deletePickupAsync = async (pickup) =>
  // eslint-disable-next-line no-return-await
  await api
    .delete(`pickups/${pickup}`)
    // eslint-disable-next-line no-underscore-dangle
    .then((delpickup) => delpickup?.deliverable?._id)
    .catch((error) => error);
function* deletePickup({ payload }) {
  try {
    const pickupID = yield call(deletePickupAsync, payload);
    if (pickupID) {
      yield put(deletePickupSuccess(pickupID));
      // history.push(adminRoot);
    } else {
      yield put(deletePickupError(pickupID?.message));
    }
  } catch (error) {
    yield put(deletePickupError(error));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchListPickup),
    fork(watchUpdatePickup),
    fork(watchDeletePickup),
    fork(watchAddPickup),
  ]);
}
