/* eslint-disable prettier/prettier */
import {
  LIST_DELIVERY_DRIVER,
  LIST_DELIVERY_DRIVER_SUCCESS,
  LIST_DELIVERY_DRIVER_ERROR,
  UPDATE_DELIVERY_DRIVER,
  UPDATE_DELIVERY_DRIVER_SUCCESS,
  UPDATE_DELIVERY_DRIVER_ERROR,
  DELETE_DELIVERY_DRIVER,
  DELETE_DELIVERY_DRIVER_SUCCESS,
  DELETE_DELIVERY_DRIVER_ERROR,
} from "../constants";

const INIT_STATE = {
  drivers: [],
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_DELIVERY_DRIVER: 
      return { ...state, loading: true, error: "" };
    case LIST_DELIVERY_DRIVER_SUCCESS:
      return {
        ...state,
        loading: false,
        drivers: action.payload,
        error: "",
      };
    case LIST_DELIVERY_DRIVER_ERROR:
      return {
        ...state,
        loading: false,
        drivers: [],
        error: action.payload,
      };
    case UPDATE_DELIVERY_DRIVER:
      return { ...state, loading: true, error: '' };
    case UPDATE_DELIVERY_DRIVER_SUCCESS:
      console.log(`driver_success`, action.payload);
      // eslint-disable-next-line no-case-declarations, array-callback-return, no-underscore-dangle
      const newState = state.drivers.filter( driver => driver._id !== action.payload);
      return {
        ...state,
        loading: false,
        drivers: newState,
        error: '',
      };
    case UPDATE_DELIVERY_DRIVER_ERROR:
      return {
        ...state,
        loading: false,
        drivers: [],
        error: action.payload.message,
      };
    case DELETE_DELIVERY_DRIVER:
      return { ...state, loading: true, error: '' };
    case DELETE_DELIVERY_DRIVER_SUCCESS:
      // eslint-disable-next-line no-case-declarations, no-underscore-dangle
      const afterDelete = state.drivers.filter((driver) => driver._id !== action.payload);
      return {
        ...state,
        loading: false,
        drivers: afterDelete,
        error: '',
      };
    case DELETE_DELIVERY_DRIVER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
