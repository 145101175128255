/* eslint-disable prettier/prettier */
import {
  LIST_PRESCRIPTION,
  LIST_PRESCRIPTION_SUCCESS,
  LIST_PRESCRIPTION_ERROR,
  UPDATE_PRESCRIPTION,
  UPDATE_PRESCRIPTION_SUCCESS,
  UPDATE_PRESCRIPTION_ERROR,
  DELETE_PRESCRIPTION,
  DELETE_PRESCRIPTION_SUCCESS,
  DELETE_PRESCRIPTION_ERROR,
} from "../constants";

const INIT_STATE = {
  prescriptions: [],
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_PRESCRIPTION: 
      return { ...state, loading: true, error: "" };
    case LIST_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        prescriptions: action.payload,
        error: "",
      };
    case LIST_PRESCRIPTION_ERROR:
      return {
        ...state,
        loading: false,
        prescriptions: [],
        error: action.payload,
      };
    case UPDATE_PRESCRIPTION:
      return { ...state, loading: true, error: '' };
    case UPDATE_PRESCRIPTION_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newState = state.prescriptions.map((prescription) => {
        // eslint-disable-next-line no-underscore-dangle
        if (prescription._id === action.payload._id) {
          return action.payload;
        }
        return prescription;
      })
      return {
        ...state,
        loading: false,
        prescriptions: newState,
        error: '',
      };
    case UPDATE_PRESCRIPTION_ERROR:
      return {
        ...state,
        loading: false,
        prescriptions: [],
        error: action.payload.message,
      };
    case DELETE_PRESCRIPTION:
      return { ...state, loading: true, error: '' };
    case DELETE_PRESCRIPTION_SUCCESS:
      // eslint-disable-next-line no-case-declarations, no-underscore-dangle
      const afterDelete = state.prescriptions.filter((prescription) => prescription._id !== action.payload);
      return {
        ...state,
        loading: false,
        prescriptions: afterDelete,
        error: '',
      };
    case DELETE_PRESCRIPTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
