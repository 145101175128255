/* eslint-disable prettier/prettier */
import {
  LIST_PICKUP,
  LIST_PICKUP_SUCCESS,
  LIST_PICKUP_ERROR,
  UPDATE_PICKUP,
  UPDATE_PICKUP_SUCCESS,
  UPDATE_PICKUP_ERROR,
  DELETE_PICKUP,
  DELETE_PICKUP_SUCCESS,
  DELETE_PICKUP_ERROR,
} from "../constants";

const INIT_STATE = {
  pickups: [],
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_PICKUP: 
      return { ...state, loading: true, error: "" };
    case LIST_PICKUP_SUCCESS:
      return {
        ...state,
        loading: false,
        pickups: action.payload,
        error: "",
      };
    case LIST_PICKUP_ERROR:
      return {
        ...state,
        loading: false,
        pickups: [],
        error: action.payload,
      };
    case UPDATE_PICKUP:
      return { ...state, loading: true, error: '' };
    case UPDATE_PICKUP_SUCCESS:
      // eslint-disable-next-line no-case-declarations, no-underscore-dangle
      const newState = state.pickups.filter((pickup) => pickup._id !== action.payload._id)
      return {
        ...state,
        loading: false,
        pickups: newState,
        error: '',
      };
    case UPDATE_PICKUP_ERROR:
      return {
        ...state,
        loading: false,
        pickups: [],
        error: action.payload.message,
      };
    case DELETE_PICKUP:
      return { ...state, loading: true, error: '' };
    case DELETE_PICKUP_SUCCESS:
      // eslint-disable-next-line no-case-declarations, no-underscore-dangle
      const afterDelete = state.pickups.filter((pickup) => pickup._id !== action.payload);
      return {
        ...state,
        loading: false,
        pickups: afterDelete,
        error: '',
      };
    case DELETE_PICKUP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
