/* eslint-disable camelcase, prettier/prettier */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { auth } from 'helpers/Firebase';
import api from "../../api/API";
import {
  LIST_DELIVERY_DRIVER,
  UPDATE_DELIVERY_DRIVER,
  DELETE_DELIVERY_DRIVER,
} from "../constants";

import {
  listDeliveryDriverSuccess,
  listDeliveryDriverError,
  updateDeliveryDriverSuccess,
  updateDeliveryDriverError,
  deleteDeliveryDriverSuccess,
  deleteDeliveryDriverError,
} from "./actions";

export function* watchListDriver() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LIST_DELIVERY_DRIVER, listDrivers);
}

// eslint-disable-next-line no-unused-vars
const listDriverAsync = async () =>
  // eslint-disable-next-line no-return-await
  await api
    .get(`delivery-driver`)
    .then((drivers) => drivers)
    .catch((error) => error);
function* listDrivers() {
  try {
    const response = yield call(listDriverAsync);
    if (response.riderRegistrationRequest) {
      yield put(listDeliveryDriverSuccess(response.riderRegistrationRequest));
      // history.push(adminRoot);
    } else {
      yield put(listDeliveryDriverError(response?.message));
    }
  } catch (error) {
    yield put(listDeliveryDriverError(error));
  }
}
export function* watchUpdateDelivery() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_DELIVERY_DRIVER, updateDelivery);
}
// eslint-disable-next-line no-unused-vars
const updateDeliveryAsync = async (id) =>
  // eslint-disable-next-line no-return-await
  await api
    .put(`delivery-driver/${id}`, {})
    .then((presReturn) => presReturn)
    .catch((error) => error);
function* updateDelivery({ payload }) {
  try {
    const response = yield call(updateDeliveryAsync, payload);
    if (response.user) {
      yield put(updateDeliveryDriverSuccess(payload));
      // history.push(adminRoot);
    } else {
      yield put(updateDeliveryDriverError(response?.message));
    }
  } catch (error) {
    yield put(updateDeliveryDriverError(error));
  }
}
export function* watchDeleteDelivery() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_DELIVERY_DRIVER, deleteDelivery);
}
// eslint-disable-next-line no-unused-vars
const deleteDeliveryAsync = async (id) =>
  // eslint-disable-next-line no-return-await
  await api
    .delete(`delivery-driver/${id}`, {})
    // eslint-disable-next-line no-underscore-dangle
    .then((delDriver) => delDriver?.riderRegistrationRequest?._id)
    .catch((error) => error);
function* deleteDelivery({ payload }) {
  try {
    const orderID = yield call(deleteDeliveryAsync, payload);
    if (orderID) {
      yield put(deleteDeliveryDriverSuccess(payload));
      // history.push(adminRoot);
    } else {
      yield put(deleteDeliveryDriverError(orderID?.message));
    }
  } catch (error) {
    yield put(deleteDeliveryDriverError(error));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchListDriver),
    fork(watchUpdateDelivery),
    fork(watchDeleteDelivery),
  ]);
}
