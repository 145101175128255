import {
  LIST_ORDER,
  LIST_ORDER_SUCCESS,
  LIST_ORDER_ERROR,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_ERROR,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_ERROR,
} from '../constants';

export const listOrder = (status) => ({
  type: LIST_ORDER,
  payload: { status },
});
export const listOrderSuccess = (orders) => ({
  type: LIST_ORDER_SUCCESS,
  payload: orders,
});
export const listOrderError = (orderListErr) => ({
  type: LIST_ORDER_ERROR,
  payload: orderListErr,
});

export const updateOrder = (updateOrderPayload) => ({
  type: UPDATE_ORDER,
  payload: updateOrderPayload,
});
export const updateOrderSuccess = (updateOrderlist) => ({
  type: UPDATE_ORDER_SUCCESS,
  payload: updateOrderlist,
});
export const updateOrderError = (updateOrderErr) => ({
  type: UPDATE_ORDER_ERROR,
  payload: updateOrderErr,
});

export const deleteOrder = (order) => ({
  type: DELETE_ORDER,
  payload: order,
});
export const deleteOrderSuccess = (orderID) => ({
  type: DELETE_ORDER_SUCCESS,
  payload: orderID,
});
export const deleteOrderError = (orderErr) => ({
  type: DELETE_ORDER_ERROR,
  payload: orderErr,
});
