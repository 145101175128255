/* eslint-disable prettier/prettier */
/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

/* Prescription */
export const LIST_PRESCRIPTION = 'LIST_PRESCRIPTION';
export const LIST_PRESCRIPTION_SUCCESS = 'LIST_PRESCRIPTION_SUCCESS';
export const LIST_PRESCRIPTION_ERROR = 'LIST_PRESCRIPTION_ERROR';
export const UPDATE_PRESCRIPTION = 'UPDATE_PRESCRIPTION';
export const UPDATE_PRESCRIPTION_SUCCESS = 'UPDATE_PRESCRIPTION_SUCCESS';
export const UPDATE_PRESCRIPTION_ERROR = 'UPDATE_PRESCRIPTION_ERROR';
export const DELETE_PRESCRIPTION = 'DELETE_PRESCRIPTION';
export const DELETE_PRESCRIPTION_SUCCESS = 'DELETE_PRESCRIPTION_SUCCESS';
export const DELETE_PRESCRIPTION_ERROR = 'DELETE_PRESCRIPTION_ERROR';

/* Order */
export const LIST_ORDER = 'LIST_ORDER';
export const LIST_ORDER_SUCCESS = 'LIST_ORDER_SUCCESS';
export const LIST_ORDER_ERROR = 'LIST_ORDER_ERROR';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_ERROR = 'UPDATE_ORDER_ERROR';
export const DELETE_ORDER = 'DELETE_ORDER';
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
export const DELETE_ORDER_ERROR = 'DELETE_ORDER_ERROR';

/* User */
export const LIST_USER = 'LIST_USER';
export const LIST_USER_SUCCESS = 'LIST_USER_SUCCESS';
export const LIST_USER_ERROR = 'LIST_USER_ERROR';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

/* Deliverable */
export const LIST_DELIVERABLE = 'LIST_DELIVERABLE';
export const LIST_DELIVERABLE_SUCCESS = 'LIST_DELIVERABLE_SUCCESS';
export const LIST_DELIVERABLE_ERROR = 'LIST_DELIVERABLE_ERROR';
export const UPDATE_DELIVERABLE = 'UPDATE_DELIVERABLE';
export const UPDATE_DELIVERABLE_SUCCESS = 'UPDATE_DELIVERABLE_SUCCESS';
export const UPDATE_DELIVERABLE_ERROR = 'UPDATE_DELIVERABLE_ERROR';
export const DELETE_DELIVERABLE = 'DELETE_DELIVERABLE';
export const DELETE_DELIVERABLE_SUCCESS = 'DELETE_DELIVERABLE_SUCCESS';
export const DELETE_DELIVERABLE_ERROR = 'DELETE_DELIVERABLE_ERROR';

/* Pickup */
export const ADD_PICKUP = 'ADD_PICKUP';
export const ADD_PICKUP_SUCCESS = 'ADD_PICKUP_SUCCESS';
export const ADD_PICKUP_ERROR = 'ADD_PICKUP_ERROR';
export const LIST_PICKUP = 'LIST_PICKUP';
export const LIST_PICKUP_SUCCESS = 'LIST_PICKUP_SUCCESS';
export const LIST_PICKUP_ERROR = 'LIST_PICKUP_ERROR';
export const UPDATE_PICKUP = 'UPDATE_PICKUP';
export const UPDATE_PICKUP_SUCCESS = 'UPDATE_PICKUP_SUCCESS';
export const UPDATE_PICKUP_ERROR = 'UPDATE_PICKUP_ERROR';
export const DELETE_PICKUP = 'DELETE_PICKUP';
export const DELETE_PICKUP_SUCCESS = 'DELETE_PICKUP_SUCCESS';
export const DELETE_PICKUP_ERROR = 'DELETE_PICKUP_ERROR';

/* Pickup */
export const ADD_DELIVERY_DRIVER = 'ADD_DELIVERY_DRIVER';
export const ADD_DELIVERY_DRIVER_SUCCESS = 'ADD_DELIVERY_DRIVER_SUCCESS';
export const ADD_DELIVERY_DRIVER_ERROR = 'ADD_DELIVERY_DRIVER_ERROR';
export const LIST_DELIVERY_DRIVER = 'LIST_DELIVERY_DRIVER';
export const LIST_DELIVERY_DRIVER_SUCCESS = 'LIST_DELIVERY_DRIVER_SUCCESS';
export const LIST_DELIVERY_DRIVER_ERROR = 'LIST_DELIVERY_DRIVER_ERROR';
export const UPDATE_DELIVERY_DRIVER = 'UPDATE_DELIVERY_DRIVER';
export const UPDATE_DELIVERY_DRIVER_SUCCESS = 'UPDATE_DELIVERY_DRIVER_SUCCESS';
export const UPDATE_DELIVERY_DRIVER_ERROR = 'UPDATE_DELIVERY_DRIVER_ERROR';
export const DELETE_DELIVERY_DRIVER = 'DELETE_DELIVERY_DRIVER';
export const DELETE_DELIVERY_DRIVER_SUCCESS = 'DELETE_DELIVERY_DRIVER_SUCCESS';
export const DELETE_DELIVERY_DRIVER_ERROR = 'DELETE_DELIVERY_DRIVER_ERROR';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';
