import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
// import { auth } from 'helpers/Firebase';
import { adminRoot } from 'constants/defaultValues';
import { setCurrentUser } from 'helpers/Utils';
import api from '../../api/API';
import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
} from '../constants';

import {
  loginUserSuccess,
  loginUserError,
  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

export function* watchLoginUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

// eslint-disable-next-line no-unused-vars
const loginWithEmailPasswordAsync = async (email, password) =>
  // eslint-disable-next-line no-return-await
  await api
    .post('auth/login', { email, password })
    .then((user) => user)
    .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;
  try {
    const loginUser = yield call(loginWithEmailPasswordAsync, email, password);
    if (loginUser.user) {
      const item = {
        // eslint-disable-next-line no-underscore-dangle
        uid: loginUser.user._id,
        ...loginUser.user,
        token: loginUser.tokens[1],
      };
      setCurrentUser(item);
      api.setToken(item.token);
      yield put(loginUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(loginUserError(loginUser.message));
    }
  } catch (error) {
    yield put(loginUserError(error));
  }
}

export function* watchRegisterUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

const registerWithEmailPasswordAsync = async ({ email, password, name }) => {
  // eslint-disable-next-line camelcase
  const [first_name, last_name] = name.split(" ");
  const payload = {
    email,
    password,
    first_name,
    last_name,
  };
  // eslint-disable-next-line no-return-await
  return await api
    .post('/users/panel', payload)
    .then((user) => user)
    .catch((error) => error);
};

function* registerWithEmailPassword({ payload }) {
  const { history, user } = payload;
  try {
    const registerUser = yield call(registerWithEmailPasswordAsync, user);
    if (registerUser.token) {
      const item = {
        // eslint-disable-next-line no-underscore-dangle
        uid: registerUser.user._id,
        ...registerUser.user,
        token: registerUser.token,
        name: `${registerUser.user.first_name} ${registerUser.user.last_name}`,
      };
      setCurrentUser(item);
      api.setToken(item.token);
      yield put(registerUserSuccess(item));
      history.push(adminRoot);
    } else {
      yield put(registerUserError(registerUser.message));
    }
  } catch (error) {
    yield put(registerUserError(error));
  }
}

export function* watchLogoutUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => history.push(adminRoot);

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  api.removeToken();
  yield call(logoutAsync, history);
}

export function* watchForgotPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  // eslint-disable-next-line no-return-await
  return email;
};

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (!forgotPasswordStatus) {
      yield put(forgotPasswordSuccess('success'));
    } else {
      yield put(forgotPasswordError(forgotPasswordStatus.message));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));
  }
}

export function* watchResetPassword() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

// eslint-disable-next-line no-unused-vars
const resetPasswordAsync = async (resetPasswordCode, newPassword) => {
  // eslint-disable-next-line no-return-await
  return await api.post('/auth/reset-password', {})
    .then((user) => user)
    .catch((error) => error);
};

function* resetPassword({ payload }) {
  const { newPassword, resetPasswordCode } = payload;
  try {
    const resetPasswordStatus = yield call(
      resetPasswordAsync,
      resetPasswordCode,
      newPassword
    );
    if (!resetPasswordStatus) {
      yield put(resetPasswordSuccess('success'));
    } else {
      yield put(resetPasswordError(resetPasswordStatus.message));
    }
  } catch (error) {
    yield put(resetPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
  ]);
}
