import {
  LIST_USER,
  LIST_USER_SUCCESS,
  LIST_USER_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERROR,
} from "../constants";

export const listUser = (status) => ({
  type: LIST_USER,
  payload: { status },
});
export const listUserSuccess = (payload) => ({
  type: LIST_USER_SUCCESS,
  payload,
});
export const listUserError = (payload) => ({
  type: LIST_USER_ERROR,
  payload,
});

export const updateUser = (payload) => ({
  type: UPDATE_USER,
  payload,
});
export const updateUserSuccess = (payload) => ({
  type: UPDATE_USER_SUCCESS,
  payload,
});
export const updateUserError = (payload) => ({
  type: UPDATE_USER_ERROR,
  payload,
});

export const deleteUser = (payload) => ({
  type: DELETE_USER,
  payload,
});
export const deleteUserSuccess = (payload) => ({
  type: DELETE_USER_SUCCESS,
  payload,
});
export const deleteUserError = (error) => ({
  type: DELETE_USER_ERROR,
  payload: error,
});