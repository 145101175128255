/* eslint-disable prettier/prettier */
import {
  LIST_DELIVERY_DRIVER,
  LIST_DELIVERY_DRIVER_SUCCESS,
  LIST_DELIVERY_DRIVER_ERROR,
  UPDATE_DELIVERY_DRIVER,
  UPDATE_DELIVERY_DRIVER_SUCCESS,
  UPDATE_DELIVERY_DRIVER_ERROR,
  DELETE_DELIVERY_DRIVER,
  DELETE_DELIVERY_DRIVER_SUCCESS,
  DELETE_DELIVERY_DRIVER_ERROR,
} from '../constants';


export const listDeliveryDriver = (status) => ({
  type: LIST_DELIVERY_DRIVER,
  payload: { status },
});
export const listDeliveryDriverSuccess = (orders) => ({
  type: LIST_DELIVERY_DRIVER_SUCCESS,
  payload: orders,
});
export const listDeliveryDriverError = (orderListErr) => ({
  type: LIST_DELIVERY_DRIVER_ERROR,
  payload: orderListErr,
});

export const updateDeliveryDriver = (updateDeliveryDriverPayload) => ({
  type: UPDATE_DELIVERY_DRIVER,
  payload: updateDeliveryDriverPayload,
});
export const updateDeliveryDriverSuccess = (updateDeliveryDriverlist) => ({
  type: UPDATE_DELIVERY_DRIVER_SUCCESS,
  payload: updateDeliveryDriverlist,
});
export const updateDeliveryDriverError = (updateDeliveryDriverErr) => ({
  type: UPDATE_DELIVERY_DRIVER_ERROR,
  payload: updateDeliveryDriverErr,
});

export const deleteDeliveryDriver = (order) => ({
  type: DELETE_DELIVERY_DRIVER,
  payload: order,
});
export const deleteDeliveryDriverSuccess = (orderID) => ({
  type: DELETE_DELIVERY_DRIVER_SUCCESS,
  payload: orderID,
});
export const deleteDeliveryDriverError = (orderErr) => ({
  type: DELETE_DELIVERY_DRIVER_ERROR,
  payload: orderErr,
});
