/* eslint-disable prettier/prettier */
/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { auth } from 'helpers/Firebase';
import api from "../../api/API";
import {
  LIST_DELIVERABLE,
  UPDATE_DELIVERABLE,
  DELETE_DELIVERABLE,
} from "../constants";

import {
  listDeliverableSuccess,
  listDeliverableError,
  updateDeliverableSuccess,
  updateDeliverableError,
  deleteDeliverableSuccess,
  deleteDeliverableError,
} from "./actions";

export function* watchListDeliverable() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LIST_DELIVERABLE, listDeliverables);
}

// eslint-disable-next-line no-unused-vars
const listDeliverablesAsync = async () =>
  // eslint-disable-next-line no-return-await
  await api
    .get(`deliverables`)
    .then((user) => user)
    .catch((error) => error);
function* listDeliverables() {
  try {
    const order = yield call(listDeliverablesAsync);
    if (order.deliverables) {
      yield put(listDeliverableSuccess(order.deliverables));
      // history.push(adminRoot);
    } else {
      yield put(listDeliverableError(order?.message));
    }
  } catch (error) {
    yield put(listDeliverableError(error));
  }
}
export function* watchUpdateDeliverable() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_DELIVERABLE, updateDeliverable);
}
// eslint-disable-next-line no-unused-vars
const updateDeliverableAsync = async ({ order, order_status }) =>
  // eslint-disable-next-line no-return-await
  await api
    .put(`deliverables`, {
      order,
      order_status
    })
    .then((presReturn) => presReturn)
    .catch((error) => error);
function* updateDeliverable({ payload }) {
  try {
    const order = yield call(updateDeliverableAsync, payload);
    if (order.order) {
      yield put(updateDeliverableSuccess(order.order));
      // history.push(adminRoot);
    } else {
      yield put(updateDeliverableError(order?.message));
    }
  } catch (error) {
    yield put(updateDeliverableError(error));
  }
}
export function* watchDeleteDeliverable() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_DELIVERABLE, deleteDeliverable);
}
// eslint-disable-next-line no-unused-vars
const deleteDeliverableAsync = async (order) =>
  // eslint-disable-next-line no-return-await
  await api
    .delete(`deliverables/${order}`)
    // eslint-disable-next-line no-underscore-dangle
    .then((delOrder) => delOrder?.deliverable?._id)
    .catch((error) => error);
function* deleteDeliverable({ payload }) {
  try {
    yield put(deleteDeliverableSuccess(payload));
  } catch (error) {
    yield put(deleteDeliverableError(error));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchListDeliverable),
    fork(watchUpdateDeliverable),
    fork(watchDeleteDeliverable),
  ]);
}
