/* eslint-disable prettier/prettier */
import {
  ADD_PICKUP,
  ADD_PICKUP_ERROR,
  ADD_PICKUP_SUCCESS,
  LIST_PICKUP,
  LIST_PICKUP_SUCCESS,
  LIST_PICKUP_ERROR,
  UPDATE_PICKUP,
  UPDATE_PICKUP_SUCCESS,
  UPDATE_PICKUP_ERROR,
  DELETE_PICKUP,
  DELETE_PICKUP_SUCCESS,
  DELETE_PICKUP_ERROR,
} from '../constants';

export const addPickup = (payload) => ({
  type: ADD_PICKUP,
  payload,
});

export const addPickupSuccess = (payload) => ({
  type: ADD_PICKUP_SUCCESS,
  payload,
});

export const addPickupError = (payload) => ({
  type: ADD_PICKUP_ERROR,
  payload,
});

export const listPickup = (status) => ({
  type: LIST_PICKUP,
  payload: { status },
});
export const listPickupSuccess = (pickups) => ({
  type: LIST_PICKUP_SUCCESS,
  payload: pickups,
});
export const listPickupError = (pickupListErr) => ({
  type: LIST_PICKUP_ERROR,
  payload: pickupListErr,
});

export const updatePickup = (updatePickupPayload) => ({
  type: UPDATE_PICKUP,
  payload: updatePickupPayload,
});
export const updatePickupSuccess = (updatePickuplist) => ({
  type: UPDATE_PICKUP_SUCCESS,
  payload: updatePickuplist,
});
export const updatePickupError = (updatePickupErr) => ({
  type: UPDATE_PICKUP_ERROR,
  payload: updatePickupErr,
});

export const deletePickup = (obj) => ({
  type: DELETE_PICKUP,
  payload: obj,
});
export const deletePickupSuccess = (id) => ({
  type: DELETE_PICKUP_SUCCESS,
  payload: id,
});
export const deletePickupError = (pickupErr) => ({
  type: DELETE_PICKUP_ERROR,
  payload: pickupErr,
});
