/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { auth } from 'helpers/Firebase';
import api from "../../api/API";
import {
  LIST_USER,
  UPDATE_USER,
  DELETE_USER,
} from "../constants";

import {
  listUserSuccess,
  listUserError,
  updateUserSuccess,
  updateUserError,
  deleteUserSuccess,
  deleteUserError,
} from "./actions";

export function* watchListUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(LIST_USER, listUsers);
}

// eslint-disable-next-line no-unused-vars
const listusersAsync = async () =>
  // eslint-disable-next-line no-return-await
  await api
    .get(`users`)
    .then((user) => user)
    .catch((error) => error);
function* listUsers() {
  try {
    const user = yield call(listusersAsync);
    if (user.users) {
      yield put(listUserSuccess(user.users));
      // history.push(adminRoot);
    } else {
      yield put(listUserError(user?.message));
    }
  } catch (error) {
    yield put(listUserError(error));
  }
}
export function* watchUpdateUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_USER, updateUser);
}
// eslint-disable-next-line no-unused-vars
const updateUserAsync = async (payload) =>
  // eslint-disable-next-line no-return-await
  await api
    .put(`users/panel`, payload)
    .then((presReturn) => presReturn)
    .catch((error) => error);
function* updateUser({ payload }) {
  try {
    const user = yield call(updateUserAsync, payload);
    if (user.user) {
      yield put(updateUserSuccess(user.user));
      // history.push(adminRoot);
    } else {
      yield put(updateUserError(user?.message));
    }
  } catch (error) {
    yield put(updateUserError(error));
  }
}
export function* watchDeleteUser() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_USER, deleteUser);
}
// eslint-disable-next-line no-unused-vars
const deleteUserAsync = async (id) =>
  // eslint-disable-next-line no-return-await
  await api
    .delete(`users/${id}`)
    // eslint-disable-next-line no-underscore-dangle
    .then((deletePres) => deletePres?.user?._id)
    .catch((error) => error);
function* deleteUser({ payload }) {
  try {
    const uID = yield call(deleteUserAsync, payload);
    if (uID.user) {
      yield put(deleteUserSuccess(uID));
    } else {
      yield put(deleteUserError(uID?.message));
    }
  } catch (error) {
    yield put(deleteUserError(error?.message));
  }
}
export default function* rootSaga() {
  yield all([
    fork(watchListUser),
    fork(watchUpdateUser),
    fork(watchDeleteUser),
  ]);
}
