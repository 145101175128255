/* eslint-disable prettier/prettier */
import {
  LIST_DELIVERABLE,
  LIST_DELIVERABLE_SUCCESS,
  LIST_DELIVERABLE_ERROR,
  UPDATE_DELIVERABLE,
  UPDATE_DELIVERABLE_SUCCESS,
  UPDATE_DELIVERABLE_ERROR,
  DELETE_DELIVERABLE,
  DELETE_DELIVERABLE_SUCCESS,
  DELETE_DELIVERABLE_ERROR,
} from "../constants";

const INIT_STATE = {
  deliverables: [],
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_DELIVERABLE: 
      return { ...state, loading: true, error: "" };
    case LIST_DELIVERABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        deliverables: action.payload,
        error: "",
      };
    case LIST_DELIVERABLE_ERROR:
      return {
        ...state,
        loading: false,
        deliverables: [],
        error: action.payload,
      };
    case UPDATE_DELIVERABLE:
      return { ...state, loading: true, error: '' };
    case UPDATE_DELIVERABLE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newState = state.deliverables.map((deliverable) => {
        // eslint-disable-next-line no-underscore-dangle
        if (deliverable._id === action.payload._id) {
          return action.payload;
        }
        return deliverable;
      })
      return {
        ...state,
        loading: false,
        deliverables: newState,
        error: '',
      };
    case UPDATE_DELIVERABLE_ERROR:
      return {
        ...state,
        loading: false,
        deliverables: [],
        error: action.payload.message,
      };
    case DELETE_DELIVERABLE:
      return { ...state, loading: true, error: '' };
    case DELETE_DELIVERABLE_SUCCESS:
      // eslint-disable-next-line no-case-declarations, no-underscore-dangle
      const afterDelete = state.deliverables.filter((deliverable) => deliverable._id !== action.payload);
      return {
        ...state,
        loading: false,
        deliverables: afterDelete,
        error: '',
      };
    case DELETE_DELIVERABLE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
