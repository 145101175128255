/* eslint-disable prettier/prettier */
import {
  LIST_ORDER,
  LIST_ORDER_SUCCESS,
  LIST_ORDER_ERROR,
  UPDATE_ORDER,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_ERROR,
  DELETE_ORDER,
  DELETE_ORDER_SUCCESS,
  DELETE_ORDER_ERROR,
} from "../constants";

const INIT_STATE = {
  orders: [],
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LIST_ORDER: 
      return { ...state, loading: true, error: "" };
    case LIST_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        orders: action.payload,
        error: "",
      };
    case LIST_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        orders: [],
        error: action.payload,
      };
    case UPDATE_ORDER:
      return { ...state, loading: true, error: '' };
    case UPDATE_ORDER_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const newState = state.orders.map((order) => {
        // eslint-disable-next-line no-underscore-dangle
        if (order._id === action.payload._id) {
          return action.payload;
        }
        return order;
      })
      return {
        ...state,
        loading: false,
        orders: newState,
        error: '',
      };
    case UPDATE_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        orders: [],
        error: action.payload.message,
      };
    case DELETE_ORDER:
      return { ...state, loading: true, error: '' };
    case DELETE_ORDER_SUCCESS:
      // eslint-disable-next-line no-case-declarations, no-underscore-dangle
      const afterDelete = state.orders.filter((order) => order._id !== action.payload);
      return {
        ...state,
        loading: false,
        orders: afterDelete,
        error: '',
      };
    case DELETE_ORDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return { ...state };
  }
};
